<template>
    <div>
        <v-container class="mb-3" :style="`margin-top: 14px;  padding-inline-start: ${$store.state.mini_drawer ? 18 : 23}px; padding-inline-end: 22.5px;`" fluid >
            <v-layout row wrap>
                <v-card :height="current_height + 113" width="100%">
                    <v-card-text class="text-center pt-0">
                        <v-avatar tile size="130" id="avatar_logo" style="margin-top: 1cm;">
                            <img :src="logoMain" alt="">
                        </v-avatar>
                        <h2 :style="`margin-top: 1.7cm; font-size: 28pt; color: ${$vuetify.theme.dark ? 'white' : 'black'};`"> Sorry </h2>
                        <p :style="`margin-top: 2.5cm; font-size: 18pt; color: ${$vuetify.theme.dark ? '#BFBFC5' : '#6a6a6c'};`"> Your Account has been disabled  </p>
                    </v-card-text>
                </v-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logoMain: require('./../assets/main-logo.svg'),
        }
    },
    computed: {
        current_height() {
            return document.body.clientHeight - 200;
        }
    }
}
</script>

<style>

</style>