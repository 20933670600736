<template>
    <v-card class="message-dialog-card">
        <v-card-title>
            <img :src="logoMain" alt="" style="height: 28px; width: 28px;">  <span style="font-size: 16px; font-weight: 400;"> &nbsp; <span style="margin-top: -25px;"> Xera Clinic </span> </span>
        </v-card-title>
        <v-card-text  class="text-center">
            <p  :class="{'white--text': $vuetify.theme.dark, 'black--text': !$vuetify.theme.dark}" class="message-dailog-text" style="font-size: 15px; margin-top: 20px;"> {{ text }} </p>
        </v-card-text>
        <v-card-actions class="justify-center mt-n1">
            <v-container fluid>
                <v-layout row wrap class="mb-1">
                    <v-flex xs12 class="text-center">
                        <v-btn color="primary" class="white--text" style="width: 137px; height: 35px !important;" @click="$emit('ok-action')"> OK </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
            
        </v-card-actions>
        
    </v-card>
</template>

<script>
export default {
    props: ['text'],
    data() {
        return {
            logoMain: require('./../assets/main-logo.svg'),
        }
    }
}
</script>

