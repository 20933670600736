
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'
import languages from './languages.json'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        lab: {},
        lab_setting: {},
        display_chart_in_print_sample: true
    },
    getters: {
        lab: state => state.lab,
        lab_setting: state => state.lab_setting,
    },
    mutations: {
        
    },
    actions: {
        
    },
})