<template>
    <v-card class="message-dialog-card">
        <v-card-title>
            <img :src="logoMain" alt="" style="height: 28px; width: 28px;">  <span style="font-size: 16px; font-weight: 400;"> &nbsp; <span style="margin-top: -25px;"> Xera Clinic </span> </span>
        </v-card-title>
        <v-card-text class="pe-6" style="margin-top: 8px;">
            <p :class="{'white--text': $vuetify.theme.dark, 'black--text': !$vuetify.theme.dark}" class="message-dailog-text" style="font-size: 12px;" v-html="text"> </p>
        </v-card-text>
        <v-card-actions class="justify-center mt-n1">
            <v-container fluid>
                <v-layout row wrap class="mb-1">
                    <v-flex sm6 class="px-7">
                        <v-btn block class="cancel-btn-in-dialog" style="height: 35px !important;" @click="$emit('cancel-action')"> Cancel </v-btn>
                    </v-flex>
                    <v-flex sm6 class="px-7">
                        <v-btn block color="#E53535" class="white--text" style="height: 35px !important;" @click="$emit('archive-action')"> Archive </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
            
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: ['text'],
    data() {
        return {
            logoMain: require('./../assets/main-logo.svg'),
        }
    }
}
</script>

