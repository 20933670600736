import Vue from 'vue'
import VTiptap from "@peepi/vuetify-tiptap";
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import globalFunctions from './globalFunctions'
import DeleteDialog from './components/Delete-Dialog.vue'
import UnverifyDialog from './components/Unverify-Dialog.vue'
import ArchiveDialog from './components/Archive-Dialog.vue'
import UnarchiveDialog from './components/Unarchive-Dialog.vue'
import ConfirmDialog from './components/Confirm-Dialog.vue'
import AgreeDialog from './components/Agree-Dialog.vue'
import MessageDialog from './components/Message-Dialog.vue'
import ComingsoonDialog from './components/Comingsoon-Dialog.vue'
import Snackbar from './components/Snackbar.vue'
import Pagination from './components/Pagination.vue'
import VueApexCharts from 'vue-apexcharts'


Vue.component('apexchart', VueApexCharts)
Vue.component('Delete-Dialog', DeleteDialog);
Vue.component('Unverify-Dialog', UnverifyDialog);
Vue.component('Archive-Dialog', ArchiveDialog);
Vue.component('Unarchive-Dialog', UnarchiveDialog);
Vue.component('Confirm-Dialog', ConfirmDialog);
Vue.component('Agree-Dialog', AgreeDialog);
Vue.component('Message-Dialog', MessageDialog);
Vue.component('Comingsoon-Dialog', ComingsoonDialog);
Vue.component('Snackbar', Snackbar);
Vue.component('Pagination', Pagination);

Vue.use(VTiptap);
Vue.use(VueApexCharts)

Vue.config.productionTip = false
import axios from 'axios'

Vue.mixin({
    methods: {
        ...globalFunctions
    }
})

var api = '';

if(process.env.NODE_ENV == 'development'){
    api = 'http://localhost:5000/'
    Vue.prototype.$image_url = 'http://localhost:5000/'
} else {
    api = 'https://api.xeraclinic.com/'
    Vue.prototype.$image_url = 'https://api.xeraclinic.com/'
}

axios.defaults.baseURL = api; 
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.response.use(r =>{
    console.log('URL: '+ r.config.url);
    console.log(r);
    return r
})



new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
        
        